<template>
  <PageCard
    pageName="Report Access"
    :iconClass="['cuis-spreadsheet', 'card-icon-font']"
  >
    <template slot="page_content">
      <div class="row">
        <div class="col-sm-12">
          <h5>Provide Report Access</h5>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-2">
          Access Type:
        </div>
        <div class="col-sm-3 pl-0">
          <b-form-select
            v-model="accessType"
            :options="accessOptions"
            :class="{
              'is-invalid': $v.accessType.$error,
              'is-valid': !$v.accessType.$invalid
            }"
            @input="accessTypeSelected"
          >
            <template slot="first">
              <option :value="null" disabled>Please select</option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback v-if="!$v.accessType.required"
            >can't be blank</b-form-invalid-feedback
          >
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-sm-2">
          Select User/Group:
        </div>
        <div class="col-sm-5 pl-0">
          <Multiselect
            track-by="value"
            label="text"
            v-model="accessByResource"
            :allowEmpty="false"
            :showLabels="false"
            :options="accessByOptions"
            :class="[
              {
                'is-invalid': $v.accessByResource.$error,
                'is-valid': !$v.accessByResource.$invalid
              },
              'simple-select form-control rounded'
            ]"
          >
          </Multiselect>
          <b-form-invalid-feedback v-if="!$v.accessByResource.required"
            >can't be blank</b-form-invalid-feedback
          >
        </div>
        <div class="col-sm-1 pl-0 d-flex align-self-center">
          <v-wait for="fetchingAccessByOptions">
            <template slot="waiting">
              <h6 class="mb-0">Loading ...</h6>
            </template>
          </v-wait>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-sm-2">
          Start Period:
        </div>
        <div class="col-sm-3 pl-0">
          <!-- <b-form-input
            v-model="periodStart"
            placeholder="Eg: 20190630"
            :class="{
              'is-invalid': $v.periodStart.$error,
              'is-valid': !$v.periodStart.$invalid
            }"
          > -->
          <div
            class="col-sm-12 date-col pl-0"
            :class="{
              'is-invalid': $v.periodStart.$error,
              'is-valid': !$v.periodStart.$invalid
            }"
          >
            <datepicker
              v-model="periodStart"
              placeholder="Start Period"
              format="yyyyMMdd"
              :bootstrap-styling="true"
            ></datepicker>
          </div>
          <!-- </b-form-input> -->
          <b-form-invalid-feedback v-if="!$v.periodStart.required"
            >can't be blank</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.periodStart.isValidFormat"
            >invalid format</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.periodStart.isBefore"
            >is after end period</b-form-invalid-feedback
          >
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-sm-2">
          End Period:
        </div>
        <div class="col-sm-3 pl-0">
          <!-- <b-form-input
            v-model="periodEnd"
            placeholder="Eg: 20190630"
            :class="{
              'is-invalid': $v.periodEnd.$error,
              'is-valid': !$v.periodEnd.$invalid
            }"
          >
          </b-form-input> -->
          <div
            class="col-sm-12 date-col pl-0"
            :class="{
              'is-invalid': $v.periodStart.$error,
              'is-valid': !$v.periodStart.$invalid
            }"
          >
            <datepicker
              v-model="periodEnd"
              placeholder="End Period"
              format="yyyyMMdd"
              :bootstrap-styling="true"
            ></datepicker>
          </div>
          <b-form-invalid-feedback v-if="!$v.periodEnd.required"
            >can't be blank</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.periodEnd.isValidFormat"
            >invalid format</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-if="!$v.periodEnd.isAfter"
            >is before start period</b-form-invalid-feedback
          >
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-sm-2">
          <b> Select FI: </b>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-sm-12">
          <SearchFinancialInstitution
            @fiSelected="fiSelected"
            :isInValid="$v.selectedFiIds.$error"
          ></SearchFinancialInstitution>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-sm-12 d-flex justify-content-center">
          <router-link
            tag="button"
            :to="{ path: 'accessible_reports' }"
            class="btn btn-secondary fw-btn mt-3 mx-2"
          >
            Cancel
          </router-link>
          <b-button
            variant="primary"
            class="fw-btn mt-3 mx-2"
            size=""
            @click="provideAccess"
          >
            Provide Access
          </b-button>
        </div>
      </div>
    </template>
  </PageCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PageCard from '@/modules/core/components/layouts/PageCard'
import SearchFinancialInstitution from '@/modules/core/components/SearchFinancialInstitution'
import reportAPI from '../../api/reports'
import Multiselect from 'vue-multiselect'
import { required } from 'vuelidate/lib/validators'
import * as moment from 'moment'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'SelectReportFI',
  components: {
    PageCard,
    Multiselect,
    SearchFinancialInstitution,
    Datepicker
  },
  data() {
    return {
      accessType: null,
      accessOptions: [
        { value: 'User', text: 'User Level' },
        { value: 'Group', text: 'Group Level' }
      ],
      accessByResource: null,
      periodStart: null,
      periodEnd: null,
      selectedFiIds: []
    }
  },
  computed: {
    ...mapGetters('SuperAdmin', ['usersSelectList', 'groupsSelectList']),
    accessByUser() {
      return this.accessType === 'User'
    },
    accessByOptions() {
      if (!this.accessType) {
        return []
      }
      return this.accessByUser ? this.usersSelectList : this.groupsSelectList
    }
  },
  methods: {
    ...mapActions('SuperAdmin', ['fetchAllUsers', 'fetchAllGroups']),
    async accessTypeSelected() {
      this.accessByResource = null
      this.$wait.start('fetchingAccessByOptions')
      await (this.accessByUser ? this.fetchAllUsers() : this.fetchAllGroups())
      this.$wait.end('fetchingAccessByOptions')
    },
    fiSelected(selectedIDs) {
      this.selectedFiIds = selectedIDs
    },
    async provideAccess() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }

      await reportAPI.addResourceReport({
        access_type: this.accessType,
        resource_id: this.accessByResource.value,
        start_period: moment(this.periodStart).format('YYYYMMDD'),
        end_period: moment(this.periodEnd).format('YYYYMMDD'),
        institution_ids: this.selectedFiIds
      })
      this.$toasted.show('Report access provided successfully', {
        icon: 'user-circle',
        type: 'success'
      })
      this.$router.push('accessible_reports')
    }
  },
  validations: {
    accessType: {
      required
    },
    accessByResource: {
      required
    },
    periodStart: {
      required,
      isBefore(date) {
        if (!date || !this.periodEnd) {
          return true
        }

        let startDate = moment(date, 'YYYYMMDD')
        let endDate = moment(this.periodEnd, 'YYYYMMDD')

        if (startDate._isValid && endDate._isValid) {
          return moment(startDate).isBefore(endDate)
        }

        return true
      },
      isValidFormat(date) {
        if (!date || !this.periodEnd) {
          return true
        }

        let startDate = moment(date, 'YYYYMMDD')
        return startDate._isValid
      }
    },
    periodEnd: {
      required,
      isAfter(date) {
        if (!date || !this.periodStart) {
          return true
        }

        let startDate = moment(this.periodStart, 'YYYYMMDD')
        let endDate = moment(date, 'YYYYMMDD')

        if (startDate._isValid && endDate._isValid) {
          return moment(endDate).isAfter(startDate)
        }
        return true
      },
      isValidFormat(date) {
        if (!date || !this.periodStart) {
          return true
        }

        let endDate = moment(date, 'YYYYMMDD')
        return endDate._isValid
      }
    },
    selectedFiIds: {
      required
    }
  }
}
</script>

<style lang="scss">
.date-col {
  .form-control {
    background-color: white;
    border-radius: 4px;
  }
  &.is-valid {
    .vdp-datepicker {
      .form-control {
        border-color: #79c447;
      }
    }

    .invalid-feedback {
      display: none;
    }
  }

  &.is-invalid {
    .vdp-datepicker {
      .form-control {
        border-color: #ff5454;
      }
    }

    .invalid-feedback {
      display: block;
    }
  }
}
</style>
