<template>
  <b-row
    :class="[
      { processing: this.loading },
      'search-container--institution fi-search'
    ]"
  >
    <b-col sm="12">
      <div :class="[{ 'is-invalid': isInValid }, 'form-control error-wrapper']">
        <div class="search-container--bar">
          <b-input-group>
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-search fa-lg"></i>
              </span>
            </div>
            <b-form class="search-form">
              <b-form-input
                v-model="searchTextField"
                placeholder="Type Institution Name to Select"
                autocomplete="off"
                class="form-control"
                type="text"
                size="lg"
                ref="searchInstitution"
              ></b-form-input>
            </b-form>
          </b-input-group>
        </div>

        <div
          class="search-container--result std-border"
          v-on:scroll.passive="fetchMoreData($event)"
        >
          <v-wait for="fetchingFIs">
            <template slot="waiting">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="13"
                ></content-placeholders-text>
              </content-placeholders>
            </template>
            <div class="text-left">
              <b-list-group v-if="emptyInstitutions" class="text-center">
                <b-list-group-item
                  ><i>No Institutions found ... </i></b-list-group-item
                >
              </b-list-group>
              <b-list-group v-else>
                <div v-for="(institution, index) in institutions" :key="index">
                  <b-list-group-item
                    @click="setInstitution(institution)"
                    :class="[
                      { selected: institutionSelected(institution) },
                      'p-2',
                      'pl-4'
                    ]"
                    :disabled="loading"
                  >
                    {{ institution.full_name }}&#44; {{ institution.city }}&#44;
                    {{ institution.state }} {{ '[' + institution.id + ']' }}
                    <span
                      class="float-right fa fa-check pt-1 add-icon"
                      v-if="institutionSelected(institution)"
                    ></span>
                  </b-list-group-item>
                </div>
              </b-list-group>
            </div>
          </v-wait>
        </div>
      </div>
      <b-form-invalid-feedback v-if="isInValid"
        >can't be blank</b-form-invalid-feedback
      >
    </b-col>
  </b-row>
</template>

<script>
import { debounce } from 'lodash'
import fiAPI from '../api/financial_institution'

export default {
  name: 'SearchFinancialInstitution',
  props: {
    isInValid: {
      Boolean,
      default: false
    }
  },
  data() {
    return {
      searchText: '',
      searchTextField: '',
      institutions: [],
      selectedInstitutions: [],
      offset: 0,
      loading: false
    }
  },
  computed: {
    emptyInstitutions() {
      return this.institutions.length === 0
    },
    selectedInstitutionIds() {
      return this.selectedInstitutions.map(si => si.id)
    }
  },
  methods: {
    search: debounce(function() {
      this.searchInstitutions()
    }, 500),
    searchInstitutions: function() {
      this.$wait.start('fetchingFIs')
      this.resetSearchData()

      fiAPI
        .search(this.searchText, this.offset)
        .then(res => {
          this.institutions = res.financial_institutions
        })
        .then(() => {
          this.$wait.end('fetchingFIs')
        })
    },
    fetchMoreData(event) {
      let el = event.target
      let elRemaining = el.scrollHeight - el.offsetHeight

      if (Math.round(el.scrollTop) - elRemaining === 1) {
        this.fetchAdditionalInstitutions()
      }
    },
    fetchAdditionalInstitutions() {
      if (this.offset < this.institutions.length) {
        this.loading = true
        this.offset = this.institutions.length
        fiAPI
          .search(this.searchText, this.offset)
          .then(res => {
            let institutionsList = this.institutions.concat(
              res.financial_institutions
            )
            this.institutions = institutionsList
          })
          .then(() => {
            this.loading = false
          })
      }
    },
    setInstitution(institution) {
      this.selectedInstitutionIds.includes(institution.id)
        ? this.removeInstitution(institution)
        : this.addInstitution(institution)
      this.$emit('fiSelected', this.selectedInstitutionIds)
    },
    addInstitution(institution) {
      let selectedIns = this.selectedInstitutions
      selectedIns.unshift(institution)
      this.selectedInstitutions = selectedIns
    },
    removeInstitution(institution) {
      this.selectedInstitutions.splice(
        this.selectedInstitutions.indexOf(institution),
        1
      )
    },
    institutionSelected(institution) {
      return this.selectedInstitutionIds.includes(institution.id)
    },
    resetSearchData() {
      this.offset = 0
      this.selectedInstitutions = []
    }
  },
  watch: {
    searchTextField(newVal) {
      if (newVal !== this.searchText) {
        this.searchText = newVal
        this.search()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-container--institution {
  &.fi-search {
    height: 23rem;
  }

  .error-wrapper {
    padding: 0;
    border: none;
  }

  .error-wrapper.is-invalid {
    border: solid 1px red;
  }
}
</style>
